import React, { useRef, useState } from 'react'
import "./contact.css";
import { NAVBAR } from '../navbar/navbar';
import { FOOTERC } from '../footer/footer';
import axios from "axios";

import ReCAPTCHA from "react-google-recaptcha";

export const CONTACT = () => {

    const [ingles, setingles]=useState(
        localStorage.getItem("ingles_curiel_web") === null
          ? false
          : localStorage.getItem("ingles_curiel_web") === "true"
          ? true
          : false
      );

      const captcha = useRef(null);
        const [name, setname] = useState("");
        const [email, setemail] = useState("");
        const [city, setcity] = useState("");
        const [msj, setmsj] = useState("");

        const verificar = () =>{
            if(captcha.current.getValue()){
                console.log("El usuario no es un robot");
            }
        }
    
        const verificarcorreo =(email)=>{
            const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return regexCorreo.test(email);
        }
    
    
        const enviarCorreo = ()=> {
            
            if (captcha.current.getValue()) {
    
                if(name==="" || name?.trim()=="" || email === "" || email?.trim()==="" || msj === ""){
                    alert("Complete los campos del formulario");
                    return null;
                }           
                const d = verificarcorreo(email);
                if(d===false){
                    alert("Correo no válido");
                    return null;
                }
    
                axios.post('https://4akuhejwr1.execute-api.us-east-1.amazonaws.com/prod/send-email',
                    { 
                        name: name, 
                        email: email,
                        city: city,
                        message: msj,      
                    }
                )
                .then(function (res) {
                    console.log(res)
                    setname("");
                    setcity("");
                    setemail("");
                    setmsj("");
                   
                })
                .catch(function (err) {
                    console.log(err);
                });
    
            }else{
                alert("Completa el CAPTCHA antes de enviar el formulario.");
                return;
            }
        }
    

  return (
    <>
    <NAVBAR op={2} ingles={ingles} setingles={setingles}></NAVBAR>
      


    <div className="contact-container" id="contact-container">
     <div className="capa-contact"></div>
        <div className="contact-grid">
            <div className="formulario">
            <h2>{ingles===true?"Contact":"Contacto"}</h2>
            <div className="formulario-inputs">
            <input placeholder={ingles===true?"Name":'Nombre'} value={name} onChange={(e)=>setname(e.target.value)}></input>
                    <input placeholder={ingles===true?"Email":'Correo Electrónico'} value={email} onChange={(e)=>setemail(e.target.value)}></input>
                    <input placeholder={ingles===true?"City":'Ciudad'} value={city} onChange={(e)=>setcity(e.target.value)}></input>
                    <textarea placeholder={ingles===true?"Message":'Mensaje'} value={msj} onChange={(e)=>setmsj(e.target.value)}></textarea>
              </div>
            <div className='input-captcha'>
                        <ReCAPTCHA
                            ref={captcha}
                            sitekey="6LfUNm4oAAAAAGXAGIJh5DYP1sVjIOKJepXJwe4b"
                            onChange={verificar}
                        />
                </div>
               <div className="button-enviar">
                <button onClick={()=>enviarCorreo()}>{ingles===true?"SUBMIIT":"ENVIAR"}</button>
                </div>
            </div>   
            <div className="mapadir"><h2>{ingles===true?"Address":"Dirección"}</h2>
            <div className="dir-grid-contac">
                <div className="dir-map1">
                    <p className="dirmap">
                        {
                            ingles === true?"North Office: Carlos González #21, Los Ángeles, Torreón, Coah. Zip Code 27140.":"Oficina Norte: Carlos González #21, Los Ángeles Torreón, Coah. C.P. 27140."
                        }
                        
                    </p>
                    <iframe loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3599.626800097936!2d-103.44829902509801!3d25.550805417288434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868fdbd6ca085629%3A0x63b33b702c7f0c39!2sCarlos%20Gonz%C3%A1lez%2021%2C%20Los%20%C3%81ngeles%2C%2027140%20Torre%C3%B3n%2C%20Coah.!5e0!3m2!1ses-419!2smx!4v1728662061366!5m2!1ses-419!2smx"></iframe>
                </div>
                <div className="line">
                    <div>
                </div>
            </div>
            <div className="dir-map2">
                <p className="dirmap">
                    {
                        ingles===true?"Central Office: Monte Tauro 250, Lomas de Chapultepec, Miguel Hidalgo, Mexico City, Zip Code 11000.":"Oficina Centro:Monte Tauro 250 Lomas de Chapultepec, Miguel Hidalgo, Ciudad de México, C.P. 11000"
                    }
                    
                </p>
                <iframe loading="lazy" 
                    src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1330.380456846814!2d-99.2156966358452!3d19.41741000149705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sCentral%20Office%3A%20Monte%20Tauro%20250%2C%20Lomas%20de%20Chapultepec%2C%20Miguel%20Hidalgo%2C%20Mexico%20City%2C%20Zip%20Code%2011000!5e0!3m2!1ses-419!2smx!4v1728662249702!5m2!1ses-419!2smx">
                </iframe>
                </div>


            </div>
        </div>
    </div>
    </div>


    <FOOTERC ingles={ingles}></FOOTERC>
   
    </>
  )
}
